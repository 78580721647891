.pagestage-article {
  position: relative;
  margin-bottom: var(--spacing-4xl);

  .cmp-cta__image,
  &__video-root {
    position: static;
    height: toRem(514px);
    margin-bottom: var(--spacing-lg);

    img,
    .cmp-video-player {
      border-radius: 8px;
    }

    &::after {
      content: none;
    }

    & * {
      height: 100%;
    }
  }

  &__content {
    &-wrapper {
      .pagestage-article__content-subtitle {
        @include bold-md;

        margin-bottom: var(--spacing-xs);
      }
    }

    &__title {
      @include bold-5xl;

      margin-bottom: var(--spacing-lg);
    }
  }

  &--centered {
    .pagestage-article__content-wrapper {
      max-width: toRem(770px);
      margin: 0 auto;
    }
  }

  @media (--breakpoint-down-md) {
    .cmp-cta__image,
    &__video-root {
      height: toRem(380px);
    }
  }

  @media (--breakpoint-down-sm) {
    .cmp-cta__image,
    &__video-root {
      height: toRem(200px);
    }

    &__content {
      &__title {
        @include bold-3xl;
      }
    }
  }
}

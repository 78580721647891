:root {
  --rebrand-text-gradient:
    linear-gradient(
      to right top,
      #b63939,
      #9b2d30,
      #812127,
      #68161e,
      #500c15,
      #420912,
      #35070d,
      #290205,
      #220206,
      #1b0106,
      #100104,
      #000
    );
  --rebrand-text-gradient-light: linear-gradient(to right top, #b63939, #d49496, #fff, #fff, #fcfcfc, #fff, #fefefe, #fff);
}

.page-stagev2 {
  margin: 0 var(--grid-anti-margin);

  .cmp-breadcrumb__list {
    margin-right: var(--grid-margin);
    margin-bottom: var(--spacing-xxxl);
  }

  /* Vertical Cropping */
  .page-stagev2__wrapper video {
    position: absolute;
    height: auto;
  }

  .page-stagev2__wrapper--crop-top video {
    top: 0;
    bottom: auto;
  }

  .page-stagev2__wrapper--crop-middle video {
    top: 50%;
    transform: translateY(-50%);
  }

  .page-stagev2__wrapper--crop-bottom video {
    bottom: 0;
    top: auto;
  }

  @media (--breakpoint-up-md) {
    /* stylelint-disable-next-line selector-no-qualifying-type */
    .cmp-pagestage__dynamicmediaplayer--top video,
    .cmp-pagestage__dynamicmediaplayer--middle video,
    .cmp-pagestage__dynamicmediaplayer--bottom video {
      /* stylelint-disable-next-line declaration-no-important */
      height: auto !important;
    }
  }

  /* Vertical Cropping End */

  .page-stagev2__wrapper {
    display: flex;

    .cmp-cta__image {
      &::after {
        content: none;
      }
    }

    .button {
      justify-content: flex-start;
    }

    .image-wrapper,
    .image-wrapper .cmp-responsive-image {
      height: 100%;
      width: 100%;
    }

    .cmp-banner-cta__content-inner {
      margin: 0 0 var(--spacing-xxxl) var(--spacing-xxxl);

      .cmp-banner-cta__preline {
        display: block;
        align-items: center;
        margin-bottom: var(--spacing-sm);
      }

      .page-stagev2-inner__preline {
        @include regular-sm();

        text-transform: uppercase;
        display: inline-block;
        margin-top: var(--spacing-sm);
      }

      .page-stagev2-inner__badge {
        @include regular-sm();

        border: var(--spacing-xxxs) solid var(--color-gray-700);
        color: var(--color-white);
        background-color: var(--color-gray-700);
        padding: var(--spacing-xxs) var(--spacing-sm);
        margin-right: var(--spacing-sm);
        white-space: nowrap;
        display: inline-block;
      }

      .page-stagev2-inner__title {
        @include bold-4xl();

        margin-bottom: var(--spacing-sm);
      }

      .page-stagev2-inner__text {
        @include regular-xl();

        margin-bottom: toRem(18px);
      }

      .cmp-button {
        margin: 0;

        &:active {
          background-color: var(--color-primary-100);
          color: var(--color-primary-800);
          outline: var(--border-xs) solid var(--color-primary-800);
        }
      }
    }

    .cmp-banner-cta__content--redTrapezoid,
    .cmp-banner-cta__content--darkTrapezoid {
      padding-left: 0;
    }
  }

  .page-stagev2__wrapper--text-only {
    height: toRem(215px);
    background-color: transparent;
    color: var(--color-black);
  }

  .page-stagev2__wrapper--small {
    height: toRem(260px);
  }

  .page-stagev2__wrapper--small,
  .page-stagev2__wrapper--text-only {
    .cmp-banner-cta__content-inner {
      .page-stagev2-inner__title {
        @include bold-4xl();

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .page-stagev2-inner__text {
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }
  }

  .page-stagev2__wrapper--medium {
    height: toRem(360px);

    .cmp-banner-cta__content-inner {
      .page-stagev2-inner__title {
        @include bold-4xl();

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }

      .page-stagev2-inner__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }
  }

  .page-stagev2__wrapper--large {
    height: toRem(600px);

    .cmp-banner-cta__content-inner {
      .page-stagev2-inner__title {
        @include bold-5xl();

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }

      .page-stagev2-inner__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }
  }

  .page-stagev2__video-root {
    position: absolute;
    width: 100%;
    height: 100%;

    @media #{$mq-mobile} {
      &[data-mobile-video-disabled='true'] {
        display: none;
      }
    }
  }

  .cmp-pagestage__dynamicmediaplayer {
    max-height: inherit;

    @media (--breakpoint-header-down-md) {
      min-height: 100%;
      min-width: 100%;
      width: auto;
    }
  }

  @media (--breakpoint-header-up-md) {
    .cmp-banner-cta__pattern--top-left::before {
      width: toRem(552px);
      left: 0;
    }

    .cmp-banner-cta__pattern--top-right::before,
    .cmp-banner-cta__pattern--bottom-right::before {
      width: toRem(552px);
      right: 0;
    }

    .cmp-banner-cta__pattern--top-right-triple::before {
      right: unset;
      left: calc(100vw - 650px);
    }
  }

  @media (--breakpoint-header-down-md) {
    .cmp-banner-cta__pattern--top-left::before,
    .cmp-banner-cta__pattern--top-right::before,
    .cmp-banner-cta__pattern--bottom-right::before,
    .cmp-banner-cta__pattern--top-right-triple::before {
      right: 0;
    }
  }

  @media (--breakpoint-up-sm) {
    .page-stagev2__wrapper {
      .cmp-banner-cta__content--side-aligned {
        max-width: 50%;
        width: 100%;
        position: inherit;
      }
    }

    .page-stagev2__wrapper--redTrapezoid .cmp-banner-cta__content {
      background-color: var(--color-primary-500);
    }

    .page-stagev2__wrapper--darkTrapezoid .cmp-banner-cta__content {
      background-color: var(--color-black-transparent-lighter);
    }

    .page-stagev2__wrapper--darkTrapezoid .page-stagev2__trapezoid-arrow {
      background-color: var(--color-black-transparent-lighter);
    }

    .page-stagev2__wrapper--redTrapezoid .page-stagev2__trapezoid-arrow {
      background-color: var(--color-primary-500);
    }

    .page-stagev2__wrapper--darkTrapezoid .page-stagev2__trapezoid-arrow,
    .page-stagev2__wrapper--redTrapezoid .page-stagev2__trapezoid-arrow {
      clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
      height: toRem(600px);
      max-width: toRem(500px);
      width: 100%;
    }
  }

  //TODO: Extract REBRAND STYLES
  &--rebrand {
    @media (--breakpoint-up-md) {
      margin: 0 0 var(--spacing-lg);
    }

    @media (--breakpoint-down-md) {
      margin: 0 var(--grid-anti-margin) var(--spacing-lg);
    }

    .cmp-breadcrumb {
      margin-bottom: 0;
    }

    .cmp-banner-cta__content-inner .page-stagev2-inner__text {
      margin: 0;
      padding: 0;
    }

    .cmp-breadcrumb__list {
      margin-right: var(--grid-margin);
      margin-bottom: var(--spacing-xxxl);

      @media (--breakpoint-down-md) {
        margin-left: var(--spacing-lg);
      }

      @media (--breakpoint-up-lg) {
        max-width: 1200px;
        margin: 0 auto var(--spacing-xxxl) auto;
      }
    }

    .page-stagev2__wrapper--medium {
      height: toRem(400px);

      @media (--breakpoint-up-md) {
        border-radius: var(--border-xxl);
      }

      /* stylelint-disable-next-line no-duplicate-selectors */
      .cmp-banner-cta__content-inner {
        padding: toRem(21px) toRem(28px);

        @media (--breakpoint-up-md) {
          backdrop-filter: blur(var(--spacing-md));
          max-width: toRem(446px);
        }

        @media (--breakpoint-down-md) {
          margin: 0;
        }
      }

      .cmp-banner-cta__content-dark .cmp-banner-cta__content-inner {
        @media (--breakpoint-up-md) {
          background: #0009;
          border-radius: var(--border-xxl);
          border: var(--border-xs) solid var(--color-gray-300);
        }
      }

      .cmp-banner-cta__content-light .cmp-banner-cta__content-inner {
        color: var(--color-gray-900);

        @media (--breakpoint-up-md) {
          background: var(--color-white-op-60);
          border-radius: var(--border-xxl);
          border: var(--border-xs) solid var(--color-gray-300);
        }
      }

      @media (--breakpoint-down-md) {
        .cmp-banner-cta__content {
          background: none;
        }

        .cmp-banner-cta__content-dark.cmp-banner-cta__content {
          background: var(--rebrand-text-gradient);
          max-width: 100%;
        }

        /* stylelint-disable-next-line no-duplicate-selectors */
        .cmp-banner-cta__content-light.cmp-banner-cta__content {
          background: var(--rebrand-text-gradient-light);
          max-width: 100%;

          &-inner {
            color: var(--color-gray-900);
          }
        }

        &.cmp-banner-cta__wrapper {
          height: 100%;
          flex-direction: column;
          background: hsl(0deg 100% 40% / 100%);
          background: var(--rebrand-text-gradient);
        }

        .cmp-cta__image,
        .cmp-video-wrapper,
        .cmp-video-player,
        img {
          max-height: toRem(375px);
        }

        .cmp-cta__image {
          position: unset;
          overflow: hidden;
        }

        &.page-stagev2__wrapper {
          height: auto;
        }

        &.page-stagev2__wrapper--styling-none::after {
          background: 0;
        }
      }

      @media (--breakpoint-down-sm) {
        .cmp-cta__image,
        .cmp-video-wrapper,
        .cmp-video-player,
        img {
          height: toRem(200px);
        }
      }
    }

    .page-stagev2__wrapper--large {
      height: toRem(514px);

      @media (--breakpoint-up-md) {
        border-radius: var(--border-xxl);
      }

      /* stylelint-disable-next-line no-duplicate-selectors */
      .cmp-banner-cta__content-inner {
        padding: toRem(21px) toRem(28px);

        @media (--breakpoint-down-md) {
          margin: 0;
        }
      }

      .cmp-banner-cta__content-dark .cmp-banner-cta__content-inner {
        @media (--breakpoint-up-md) {
          background: #0009;
          border-radius: var(--border-xxl);
          border: var(--border-xs) solid var(--color-gray-300);
        }
      }

      .cmp-banner-cta__content-light .cmp-banner-cta__content-inner {
        color: var(--color-gray-900);

        @media (--breakpoint-up-md) {
          background: var(--color-white-op-60);
          border-radius: var(--border-xxl);
          border: var(--border-xs) solid var(--color-gray-300);
        }
      }

      @media (--breakpoint-down-md) {
        .cmp-banner-cta__content-dark.cmp-banner-cta__content {
          background: var(--rebrand-text-gradient);
          max-width: 100%;
        }

        /* stylelint-disable-next-line no-duplicate-selectors */
        .cmp-banner-cta__content-light.cmp-banner-cta__content {
          background: var(--rebrand-text-gradient-light);
          max-width: 100%;

          &-inner {
            color: var(--color-gray-900);
          }
        }

        &.cmp-banner-cta__wrapper {
          height: 100%;
          flex-direction: column;
          background: var(--rebrand-text-gradient);
        }

        .cmp-cta__image,
        .cmp-video-wrapper,
        .cmp-video-player {
          max-height: toRem(375px);
        }

        .cmp-cta__image {
          position: unset;
          overflow: hidden;
        }

        &.page-stagev2__wrapper {
          height: auto;

          &:not(.page-stagev2__wrapper--text-only) {
            min-height: auto;
          }
        }

        &.page-stagev2__wrapper--styling-none::after {
          background: 0;
        }
      }

      @media (--breakpoint-down-sm) {
        .cmp-cta__image,
        .cmp-video-wrapper,
        .cmp-video-player {
          height: toRem(200px);
        }
      }
    }
  }
}

// Full overlay
.page-stagev2__wrapper--fullOverlay::after,
.page-stagev2__wrapper--leftOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.page-stagev2__wrapper--fullOverlay::after {
  background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 0%) 0.01%, rgb(0 0 0 / 75%) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.page-stagev2__wrapper--leftOverlay::after {
  background: linear-gradient(90deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 60%) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.slide__brandingPattern {
  @include brandingPattern;
}

@media (--breakpoint-down-md) {
  .page-stagev2 {
    .page-stagev2__wrapper {
      .cmp-banner-cta__content--side-aligned {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
        position: unset;
      }

      .cmp-banner-cta__content.cmp-banner-cta__content--redTrapezoid,
      .cmp-banner-cta__content.cmp-banner-cta__content--darkTrapezoid {
        .cmp-banner-cta__content-inner {
          padding: toRem(133px) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
          clip-path: polygon(0 0, 100% 133px, 100% 100%, 0% 100%);
          margin: toRem(150px) 0 0;
          bottom: 0;
          width: 100%;
        }
      }

      .cmp-banner-cta__content-inner {
        margin: var(--spacing-lg);
        padding: 0;

        .page-stagev2-inner__preline {
          @include regular-sm();

          text-transform: uppercase;
          gap: toRem(4px);
        }
      }
    }

    .cmp-banner-cta__pattern--top-left::before {
      left: unset;
    }
  }
}

@media (--breakpoint-down-sm) {
  .page-stagev2 {
    position: relative;

    video {
      height: 100%;
    }

    .page-stagev2__wrapper:not(.page-stagev2__wrapper--text-only) {
      // TODO: update with 3 * 7XL, once ABB-9812 is done
      min-height: toRem(408px);
    }

    .page-stagev2__wrapper {
      height: fit-content;
      display: flex;

      .cmp-banner-cta__content {
        align-self: end;
      }

      .cmp-banner-cta__content-inner .page-stagev2-inner__title {
        @include bold-3xl();
      }

      .cmp-banner-cta__content.cmp-banner-cta__content--redTrapezoid {
        background-color: transparent;

        .cmp-banner-cta__content-inner {
          background-color: var(--color-primary-500);
        }
      }

      .cmp-banner-cta__content.cmp-banner-cta__content--darkTrapezoid {
        background-color: transparent;

        .cmp-banner-cta__content-inner {
          background-color: var(--color-black-transparent-lighter);
        }
      }
    }

    .page-stagev2__wrapper--text-only {
      .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
        min-height: fit-content;
      }

      &::after {
        /* stylelint-disable-next-line declaration-no-important */
        background: transparent !important;
      }
    }

    .page-stagev2__wrapper--styling-none::after {
      content: '';
      padding-top: 0;
      background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 0%) 0.01%, rgb(0 0 0 / 75%) 100%);
      top: 0;
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .cmp-container--full-width .cmp-banner-cta__wrapper.page-stagev2__wrapper {
    height: 70vh;
  }
}

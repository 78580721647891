.cmp-horizontalteaser-v2 {
  @media (--breakpoint-down-sm) {
    width: 100vw;
    margin-left: var(--grid-anti-margin);
  }

  &__wrapper {
    position: relative;

    @media (--breakpoint-up-sm) {
      &:has(.cmp-horizontalteaser-v2__content--bottom-left, .cmp-horizontalteaser-v2__content--bottom-right) {
        .cmp-horizontalteaser-v2__media-wrapper {
          padding-bottom: var(--spacing-5xl);
        }
      }

      &:has(.cmp-horizontalteaser-v2__content--bottom-left, .cmp-horizontalteaser-v2__content--center-left) {
        .cmp-horizontalteaser-v2__image,
        .cmp-video-wrapper {
          margin-left: auto;
          margin-right: 0;
        }
      }

      &:has(.cmp-horizontalteaser-v2__content--bottom-right, .cmp-horizontalteaser-v2__content--center-right) {
        .cmp-horizontalteaser-v2__image,
        .cmp-video-wrapper {
          margin-left: 0;
        }
      }

      .cmp-horizontalteaser-v2__image,
      .cmp-video-wrapper {
        width: 74.5%;
      }

      .cmp-horizontalteaser-v2__image img,
      .cmp-video-player {
        border-radius: var(--border-radius-m);
      }
    }

    .cmp-horizontalteaser-v2__content {
      background-color: var(--color-white-op-60);
      padding: toRem(21px) toRem(28px);
      backdrop-filter: blur(var(--spacing-md));

      @media (--breakpoint-down-sm) {
        background: linear-gradient(57.58deg, rgb(204 0 0 / 36%) 0%, rgb(204 0 0 / 0%) 25.5%);
      }

      @media (--breakpoint-up-sm) {
        border: var(--border-xs) solid var(--color-gray-300);
        border-radius: var(--border-radius-m);
        width: 49%;
        position: absolute;
        z-index: 1;

        &--center-left,
        &--center-right {
          top: 50%;
          transform: translateY(-50%);
        }

        &--bottom-left,
        &--bottom-right {
          bottom: 0;
        }

        &--center-left,
        &--bottom-left {
          left: 0;
        }

        &--center-right,
        &--bottom-right {
          right: 0;
        }
      }

      &--title {
        margin-bottom: var(--spacing-md);
      }

      &--description {
        margin-bottom: var(--spacing-lg);
      }

      &--link-button {
        @include button-primary;
      }
    }

    .cmp-video-wrapper,
    .cmp-responsive-image {
      position: relative;
      aspect-ratio: 16/9;
    }
  }
}

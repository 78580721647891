.cmp-author__main-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 12.5%;
}

.cmp-author__wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: var(--spacing-xxl);
  margin-right: 10%;
}

.cmp-author__image.cmp-author__image.cmp-author__image-medium {
  width: 10rem;
}

.cmp-author__image.cmp-author__image.cmp-author__image-big {
  width: 11.25rem;
}

.cmp-author__image .image-wrapper img {
  position: relative;
  border-radius: 50%;
}

.cmp-author__wrapper-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  padding: var(--spacing-md) 0 0 var(--spacing-lg);
  gap: var(--spacing-sm);

  .cmp-author__send-email,
  .cmp-author__download,
  .cmp-author__web-link {
    @include bold-lg();

    display: flex;
    color: var(--color-primary-500);
    white-space: nowrap;
  }

  .cmp-author__web-link {
    align-items: center;
  }
}

.cmp-author__about {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-xxxl);
}

.cmp-author__about .cmp-author__about--title {
  @include bold-3xl();
}

.cmp-author__image.cmp-author__image.cmp-author__image-medium,
.cmp-author__wrapper-text.cmp-author__wrapper-text--medium {
  display: flex;
  flex-flow: column;
  align-self: center;
}

@media screen and (min-width: 1900px) {
  .cmp-author__main-wrapper {
    margin: 0 25%;
  }
}

@media (--breakpoint-down-xl) {
  .cmp-author__main-wrapper {
    margin: 0 9%;
  }
}

@media (--breakpoint-down-lg) {
  .cmp-author__main-wrapper {
    margin: 0 2.5%;
  }
}

@media (--breakpoint-down-md) {
  .cmp-author__main-wrapper {
    margin: 0;
  }
}

@media (--breakpoint-header-down-md) {
  .cmp-author__main-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .cmp-author__wrapper {
    flex-direction: column;
    align-self: center;
    margin-right: 0;
    padding-bottom: 0;
  }

  .cmp-author__about.cmp-author__about--big,
  .cmp-author__about.cmp-author__about--medium {
    margin: 0;
    padding: var(--spacing-md);
  }

  .cmp-author__wrapper-text {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm) 0;
  }

  .cmp-author__wrapper-text .cmp-author__name,
  .cmp-author__wrapper-text .cmp-author__job-title {
    text-align: center;
  }

  .cmp-author__wrapper.cmp-author__medium {
    margin: var(--spacing-md);
  }
}

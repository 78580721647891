@import '@react/common.scss';

:root {
  --info-box-border: var(--border-xl);
}

.cmp-infobox,
.cmp-infobox-container {
  width: 100%;
}

.cmp-infobox__container {
  background: var(--color-gray-50);
  padding: var(--spacing-lg) var(--spacing-md);
  border-radius: var(--border-radius-m);

  &:focus {
    outline: var(--border-s) solid var(--color-focus-500);

    .cmp-infobox__title {
      color: var(--color-primary-500);
    }
  }
}

.cmp-infobox__title {
  display: block;

  @include bold-xl;

  margin-bottom: var(--spacing-xs);

  @media #{$mq-1} {
    @include bold-lg;
  }
}

.cmp-infobox__content {
  margin-bottom: var(--spacing-lg);

  @include regular-lg;

  @media #{$mq-1} {
    @include regular-md;
  }

  p {
    @include regular-lg;

    @media #{$mq-1} {
      @include regular-md;
    }
  }
}

.cmp-infobox__content p + ol,
.cmp-infobox__content p + ul {
  margin-top: var(--spacing-md);
}

.cmp-infobox__link .cmp-button {
  display: inline-block;
  margin: 0;
}

/* style policy -- small  */
.cmp-infobox--sm .cmp-infobox__container {
  padding: var(--spacing-lg);
}

/* style policy -- highlight  */
.cmp-infobox--highlight .cmp-infobox__container {
  background: var(--color-primary-500);
  color: var(--color-white);
}

.cmp-infobox__margin-bottom {
  margin-bottom: var(--spacing-lg);
}

.cmp-infobox:focus {
  outline: 0;
}

.cmp-infobox-container--success {
  padding-top: var(--spacing-md);
}

.cmp-infobox__box {
  padding: var(--spacing-lg) var(--spacing-xxxl);
  border-width: var(--spacing-xxxs) var(--spacing-xxxs) var(--spacing-xxxs) var(--spacing-xs);
  border-style: solid;
  margin-bottom: var(--spacing-lg);

  .a-icon {
    margin-bottom: var(--spacing-md);

    .icon {
      font-size: var(--font-size-icon-lg);
    }
  }

  .cmp-infobox__title {
    @include bold-xl();

    margin-bottom: var(--spacing-sm);
  }
}

.cmp-infobox--warning {
  border-color: var(--color-error-500);

  .cmp-infobox__invalid-list {
    color: var(--color-black);
    margin-left: var(--spacing-xs);
  }

  .cmp-infobox__invalid-list-link {
    text-decoration: underline;
  }
}

.cmp-infobox--success {
  border-color: var(--color-success-500);

  .a-icon {
    margin-bottom: var(--spacing-md);

    .icon {
      color: var(--color-black);
    }
  }

  .cmp-infobox__title {
    color: var(--color-black);
  }
}

/*
  Extend background to fullWidth if breakpoint above XXL (1920px)
*/

@mixin fullWidthBackgroundColor($position) {
  position: $position;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
}

@mixin button {
  @include bold-lg;

  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-lg);
  border-radius: var(--border-radius-l);
  min-width: 6rem; /* 96px */
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: var(--border-m) solid var(--color-focus-500);
  }
}

@mixin button-primary {
  @include button;

  background-color: var(--color-primary-500);
  color: var(--color-white);
  border: var(--border-xs) solid var(--color-primary-500);

  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-black);
      border-color: var(--color-black);
      transform: none;
    }
  }

  &:disabled {
    background-color: var(--color-gray-300);
    border-color: var(--color-gray-300);
    color: var(--color-gray-500);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin button-primary-inverted {
  @include button;

  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-white);
  color: var(--color-primary-500);

  &:hover {
    color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      color: var(--color-black);
      transform: none;
    }
  }

  &:disabled {
    background-color: var(--color-gray-300);
    border-color: var(--color-gray-300);
    color: var(--color-gray-500);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin button-secondary {
  @include button;

  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-primary-500);
  outline: var(--border-xs) solid var(--color-primary-500);
  color: var(--color-primary-500);

  &:hover {
    background-color: var(--color-white);
    border-color: var(--color-black);
    outline-color: var(--color-black);
    color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-black);
      outline-color: var(--color-black);
      color: var(--color-black);
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: var(--color-gray-300);
    outline-color: var(--color-gray-300);
    color: var(--color-gray-300);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin button-secondary-inverted {
  @include button;

  background-color: transparent;
  border: var(--border-xs) solid var(--color-gray-50);
  outline: var(--border-xs) solid var(--color-gray-50);
  color: var(--color-gray-50);

  &:hover {
    background-color: var(--color-white);
    border-color: var(--color-white);
    outline-color: var(--color-white);
    color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-white);
      outline-color: var(--color-white);
      color: var(--color-black);
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: var(--color-gray-300);
    outline-color: var(--color-gray-300);
    color: var(--color-gray-300);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin gridColumns($columns) {
  grid-template-columns: repeat($columns, 1fr);
}

@mixin remove-grid-content($class-names...) {
  main .aem-GridColumn%className {
    > *::after {
      content: none;
    }
  }
  #{$class-names} {
    @extend %className;
  }
}

@include remove-grid-content('.teaser');

.cmp-teaser {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background: var(--color-gray-50);
  box-shadow: var(--box-shadow);
  position: relative;
  border-radius: var(--border-radius-m);

  &:hover:not(:has(.cmp-teaser__action-link--standard-link)) {
    .cmp-teaser__title,
    .cmp-teaser__title-text {
      color: var(--color-primary-500);
    }

    .cmp-teaser__action-link {
      background-color: var(--color-primary-500);
    }
  }

  &:focus:not(:has(.cmp-teaser__action-link--standard-link)),
  .cmp-teaser__action-link--standard-link:focus {
    outline: var(--border-m) solid var(--color-focus-500);
    border-radius: var(--border-radius-m);
  }

  &:has(.cmp-teaser__action-link--standard-link) {
    .cmp-teaser__action-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .cmp-teaser__action-link--standard-link {
      color: var(--color-black);
      gap: var(--spacing-md);

      &:hover {
        color: var(--color-primary-500);

        .cmp-teaser__action-link-text {
          text-decoration: none;
        }

        .cmp-button__icon {
          background-color: var(--color-primary-500);
        }
      }

      &:not(:last-child) {
        margin-bottom: var(--spacing-xxs);
      }
    }
  }

  .cmp-teaser__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__image {
    .cmp-image {
      position: relative;
    }

    .cmp-image__title {
      display: none;
    }

    .cmp-image:not(.cmp-image--responsive)::before {
      content: '';
      display: block;
      padding-top: 56.25%; /* 16:9 aspect ratio (divide 9 by 16 = 0.5625) */
      width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    }

    .cmp-image__image,
    .page-teaser__image {
      width: 100%;
    }
  }

  .image-wrapper {
    height: 100%;
  }

  &__content-link {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__label {
    @include regular-md();

    background: var(--color-primary-500);
    color: var(--color-white);
    padding: var(--spacing-xxs) var(--spacing-sm);
    display: inline-block;
    position: absolute;
    top: var(--spacing-lg);
    left: 0;
    z-index: 1;
    border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
  }

  &:not(:has(.cmp-teaser__image)) .cmp-teaser__label {
    top: unset;
    left: unset;
    right: 0;
    bottom: var(--spacing-xxxl);
    border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
  }

  &__content {
    padding: var(--spacing-md);
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .cmp-teaser__action-link .icon-link {
      display: none;
    }

    &.link_external .cmp-teaser__action-link {
      .cmp-button__icon {
        display: none;
      }

      .icon-link {
        display: inline-flex;
      }
    }
  }

  &__action-link::before {
    padding-bottom: var(--spacing-md);
  }

  &__meta {
    margin-top: auto;

    &:has(span) {
      margin-bottom: var(--spacing-xxs);
    }
  }

  &__tag,
  &__meta {
    @include regular-md();

    color: var(--color-gray-900);
  }

  /* separator between meta fragments */
  &__meta-tag:not(:first-child)::before {
    content: '';
    width: 0;
    height: 100%;
    margin: 0 var(--spacing-xs) 0 var(--spacing-xxs);
    border-left: var(--spacing-xxxs) solid var(--color-gray-300);
  }

  &__meta-separator {
    @include regular-xs();

    margin: 0 var(--spacing-xxs);
  }

  &__title {
    align-items: center;
    display: flex;
    word-break: break-word;
    margin: 0 0 var(--spacing-lg);
  }

  &__title-icon {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: var(--spacing-sm);
    margin-top: 0;
  }

  &__title-text {
    @include heading-5;

    color: var(--color-gray-900);
    margin: 0;
  }

  &__description {
    color: var(--color-gray-900);
    overflow-wrap: break-word;
    hyphens: auto;
    margin-bottom: var(--spacing-xxs);
  }

  &__description,
  &__description p {
    @include regular-md;
  }

  &__action-container {
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  .cmp-teaser__action-link {
    @include regular-md();

    display: flex;
    margin: 0;
    z-index: 3;
    min-width: unset;

    &:not(.cmp-teaser__action-link--standard-link),
    &.cmp-teaser__action-link--standard-link .cmp-button__icon {
      background-color: var(--color-gray-900);
      justify-content: flex-end;
      border-radius: var(--border-radius-xs);
      color: var(--color-white);
    }

    .cmp-button__icon {
      margin-top: 0;

      &::before {
        margin-right: 0;
      }
    }
  }

  /** Teaser Aspect Ratio for Standard Teaser **/
  .cmp-responsive-image {
    position: relative;
    height: 100%;

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.cmp-teasercontainer {
  &--image-hidden .cmp-teaser__image {
    display: none;
  }

  &--pictogram .teaser .cmp-responsive-image::before {
    padding-top: 100%;
  }
}

/** teaser sizing in edit mode **/
.cq-placeholder.cmp-teaser {
  height: 100%;
}

@media (--breakpoint-down-sm) {
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline .cmp-container {
    display: flex;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline .teaser,
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__tag,
.cmp-teasercontainer--image-and-headline .cmp-teaser__description {
  display: none;
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title-text {
  @include heading-5;

  color: var(--color-gray-900);
  margin: 0 0 var(--spacing-lg);
  z-index: 2;
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title:hover .cmp-teaser__title-text,
.cmp-teasercontainer--image-and-headline .cmp-teaser:hover .cmp-teaser__title .cmp-teaser__title-text {
  color: var(--color-primary-500);
}

.teasercontainer.cmp-teasercontainer--toolbox .cmp-teaser__title .cmp-button__icon--keyboard_arrow_right {
  display: inline-block;
}

.teasercontainer.cmp-teasercontainer--image-and-headline .cmp-teaser__title,
.teasercontainer.cmp-teasercontainer--image-and-headline .cmp-teaser__title .cmp-teaser__title-text + .cmp-button__icon--keyboard_arrow_right {
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
}

.cmp-teaser_title-icon-external {
  display: none;
}

.cmp-teaser_title-icon-download {
  display: none;
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title-icon--is-external .cmp-teaser__title-icon {
  display: none;
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title-icon--is-external .cmp-teaser_title-icon-external {
  display: inline;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: var(--spacing-sm);
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title-icon--is-download .cmp-teaser__title-icon {
  display: none;
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title-icon--is-download .cmp-teaser_title-icon-download {
  display: inline;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: var(--spacing-sm);
}

.cmp-teasercontainer--image-and-headline .cmp-teaser__title-link::after,
.cmp-teasercontainer--image-and-headline .cmp-teaser__action-link:first-child::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
